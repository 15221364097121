// Generated by Framer (2e9e6ed)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getFontsFromSharedStyle, RichText, SmartComponentScopedContainer, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
import { Lottie } from "https://framerusercontent.com/modules/YbkSqZ7STzW5WsMb1yan/RLfsHd78NBd0kbafA9hd/Lottie.js";
import * as sharedStyle from "../css/LyYFrseje";
const LottieFonts = getFonts(Lottie);

const serializationHash = "framer-bXT0K"

const variantClassNames = {EFLKadDGl: "framer-v-ybvgx2"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({color, height, id, title, uRL, width, ...props}) => { return {...props, FuTg_ufDr: title ?? props.FuTg_ufDr ?? "Thank you!", HcbowELhZ: uRL ?? props.HcbowELhZ ?? "https://lottie.host/9550dad1-d04e-4e3e-a212-93a255fa7867/tkyF0TTzOk.json", LN1J6OFFJ: color ?? props.LN1J6OFFJ ?? "rgb(0, 0, 0)"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;color?: string;uRL?: string;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, LN1J6OFFJ, HcbowELhZ, FuTg_ufDr, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "EFLKadDGl", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = [sharedStyle.className]

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-ybvgx2", className, classNames)} data-framer-name={"dark"} layoutDependency={layoutDependency} layoutId={"EFLKadDGl"} ref={refBinding} style={{...style}}><ComponentViewportProvider ><SmartComponentScopedContainer className={"framer-1n5fpwu-container"} isAuthoredByUser isModuleExternal layoutDependency={layoutDependency} layoutId={"PlabpLitG-container"} nodeId={"PlabpLitG"} rendersWithMotion scopeId={"dnz952Z6O"}><Lottie height={"100%"} id={"PlabpLitG"} isForwardsDirection layoutId={"PlabpLitG"} loop={false} pauseOnCanvas={false} playing poster={"Auto"} posterProgress={0} progress={0} speed={1} srcType={"URL"} srcUrl={HcbowELhZ} style={{height: "100%", width: "100%"}} width={"100%"}/></SmartComponentScopedContainer></ComponentViewportProvider><RichText __fromCanvasComponent children={<React.Fragment><motion.h3 className={"framer-styles-preset-a8ih3b"} data-styles-preset={"LyYFrseje"} style={{"--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-a0htzi, var(--variable-reference-LN1J6OFFJ-dnz952Z6O))"}}>Thank you!</motion.h3></React.Fragment>} className={"framer-ffgssf"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"zB9KHjHOi"} style={{"--extracted-a0htzi": "var(--variable-reference-LN1J6OFFJ-dnz952Z6O)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--variable-reference-LN1J6OFFJ-dnz952Z6O": LN1J6OFFJ}} text={FuTg_ufDr} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-bXT0K.framer-15e05i7, .framer-bXT0K .framer-15e05i7 { display: block; }", ".framer-bXT0K.framer-ybvgx2 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 275px; }", ".framer-bXT0K .framer-1n5fpwu-container { flex: none; height: 164px; position: relative; width: 191px; }", ".framer-bXT0K .framer-ffgssf { flex: none; height: auto; position: relative; white-space: pre-wrap; width: 100%; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-bXT0K.framer-ybvgx2 { gap: 0px; } .framer-bXT0K.framer-ybvgx2 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-bXT0K.framer-ybvgx2 > :first-child { margin-top: 0px; } .framer-bXT0K.framer-ybvgx2 > :last-child { margin-bottom: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 205
 * @framerIntrinsicWidth 275
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"LN1J6OFFJ":"color","HcbowELhZ":"uRL","FuTg_ufDr":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 * @framerColorSyntax true
 */
const Framerdnz952Z6O: React.ComponentType<Props> = withCSS(Component, css, "framer-bXT0K") as typeof Component;
export default Framerdnz952Z6O;

Framerdnz952Z6O.displayName = "thank you";

Framerdnz952Z6O.defaultProps = {height: 205, width: 275};

addPropertyControls(Framerdnz952Z6O, {LN1J6OFFJ: {defaultValue: "rgb(0, 0, 0)", title: "Color", type: ControlType.Color}, HcbowELhZ: {defaultValue: "https://lottie.host/9550dad1-d04e-4e3e-a212-93a255fa7867/tkyF0TTzOk.json", placeholder: "../example.json", title: "URL", type: ControlType.String}, FuTg_ufDr: {defaultValue: "Thank you!", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(Framerdnz952Z6O, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...LottieFonts, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})